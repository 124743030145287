<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.80039 12.4008C8.48213 12.4008 8.17691 12.2743 7.95186 12.0493C7.72682 11.8243 7.60039 11.519 7.60039 11.2008V4.10038C5.82431 4.40607 4.22736 5.3665 3.1248 6.79208C2.02224 8.21766 1.49419 10.0048 1.64489 11.8007C1.79559 13.5966 2.61409 15.2707 3.93881 16.4926C5.26353 17.7145 6.9982 18.3954 8.80039 18.4008C10.502 18.4006 12.1485 17.7979 13.4482 16.6997C14.7479 15.6014 15.6167 14.0785 15.9008 12.4008H8.80039ZM7.60399 2.88478C8.26039 2.79118 8.80039 3.33838 8.80039 4.00078V11.2008H16.0004C16.6628 11.2008 17.21 11.7408 17.1164 12.3972C16.8089 14.4956 15.7192 16.4002 14.066 17.7287C12.4128 19.0572 10.3184 19.7114 8.20292 19.56C6.08747 19.4086 4.10753 18.4628 2.66037 16.9124C1.2132 15.362 0.405883 13.3216 0.400391 11.2008C0.400497 9.1801 1.1288 7.22713 2.45183 5.6998C3.77486 4.17247 5.60398 3.17307 7.60399 2.88478ZM11.2004 8.80078V1.60078C13.11 1.60078 14.9413 2.35935 16.2916 3.70961C17.6418 5.05987 18.4004 6.89122 18.4004 8.80078H11.2004ZM18.4748 10.0008C19.0724 10.0008 19.5872 9.56158 19.5992 8.96398L19.6004 8.80078C19.6004 7.68391 19.3778 6.57824 18.9454 5.54846C18.513 4.51868 17.8796 3.58546 17.0822 2.80341C16.2849 2.02135 15.3396 1.40616 14.3016 0.993821C13.2636 0.581482 12.1539 0.380277 11.0372 0.401976C10.4396 0.413976 10.0004 0.929976 10.0004 1.52638V8.80078C10.0004 9.11904 10.1268 9.42426 10.3519 9.6493C10.5769 9.87435 10.8821 10.0008 11.2004 10.0008H18.4748Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {}
</script>

<style></style>
